<template>
  <section class="bg-darkgray">
    <div class="relative h-screen flex justify-center items-center">
      <img class="w-full h-full object-cover" src="@/assets/ceiling.jpg" />
      <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div class="flex flex-col items-center h-screen justify-center text-white">
          <h1 class="text-4xl mb-4">404</h1>
          <p>Oh no!</p>
          <p>The page you're looking for doesn't exist!</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useHead } from '@vueuse/head';

export default {
  setup() {
    useHead({
      title: `404 | ${process.env.VUE_APP_NAME}`,
    });
  },
};
</script>
