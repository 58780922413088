<template>
  <section class="bg-darkgray">
    <div class="relative h-96 flex flex-col justify-center items-center">
      <img class="w-full h-full object-cover" :src="project.headline" v-if="found" />
      <div v-if="!loading">
        <div class="text-center" v-if="found">
          <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div class="text-gray">
              <AnimateShow>
                <p class="text-4xl mb-2">
                  {{ project.title }}
                </p>
              </AnimateShow>
              <AnimateShow duration="3000">
                <span class="text-lg">{{ project.categories.join(', ') }}</span>
              </AnimateShow>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="text-xl text-gray">Project not found!</p>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div v-if="loading">
      <div class="relative flex justify-center items-center h-96">
        <div class="rounded animate-spin ease duration-300 w-5 h-5 border-2 border-primary"></div>
      </div>
    </div>

    <section v-else>
      <div class="md:container mx-auto px-6 py-12" v-if="found">
        <router-link class="group text-primary hover:text-secondary flex items-center" to="/project">
          <fa class="mr-2" :icon="['fas', 'chevron-left']" />
          <span class="text-lg">All Projects</span>
        </router-link>

        <article class="text-lg text-primary my-12" v-html="project.description" />
        <div class="mb-12">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div class="flex items-center justify-center" v-for="(image, index) in project.images" :key="image">
              <img class="cursor-pointer object-cover w-96 h-96" @click="showViewer(index)" :src="image" />
            </div>
          </div>
          <VueEasyLightbox
            scrollDisabled
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="project.images"
            :index="index"
            @hide="handleHide"
          />
        </div>

        <div class="mb-8 md:mb-12" v-for="(video, index) in project.video" :key="index">
          <Embed aspect="16/9" :src="video" />
        </div>

        <div class="clearfix">
          <router-link
            class="float-left group text-primary hover:text-secondary flex items-center"
            v-if="project.previous"
            :to="`/project/${project.previous.slug}`"
          >
            <fa class="mr-2" :icon="['fas', 'chevron-left']" />
            <span class="text-lg">{{ project.previous.title }}</span>
          </router-link>

          <router-link
            class="float-right group text-primary hover:text-secondary flex items-center"
            v-if="project.next"
            :to="`/project/${project.next.slug}`"
          >
            <span class="text-lg">{{ project.next.title }}</span>
            <fa class="ml-2" :icon="['fas', 'chevron-right']" />
          </router-link>
        </div>
      </div>
      <NotFound v-else />
    </section>
  </section>
</template>

<script>
import { computed, reactive } from 'vue';
import { useHead } from '@vueuse/head';
import VueEasyLightbox from 'vue-easy-lightbox';
import AnimateShow from '@/components/Animation/AnimateShow.vue';
import Embed from '@/components/Embed.vue';
import NotFound from '@/views/NotFound.vue';

export default {
  components: {
    VueEasyLightbox,
    AnimateShow,
    Embed,
    NotFound,
  },
  setup() {
    const meta = reactive({
      title: `${process.env.VUE_APP_NAME}`,
    });

    useHead({
      title: computed(() => `${meta.title} | ${process.env.VUE_APP_NAME}`),
    });

    return {
      meta,
    };
  },
  data() {
    return {
      found: false,
      loading: true,
      project: {},
      visible: false,
      index: 0,
    };
  },
  methods: {
    get() {
      this.loading = true;

      this.$http.get(`/project.php`, { params: { slug: this.$route.params.param } }).then((response) => {
        this.loading = false;

        if (response.data.id) {
          this.found = true;
          this.project = response.data;
          this.meta.title = this.project.title;
        } else {
          this.found = false;
        }
      });
    },
    showViewer(index) {
      this.index = index ? index : 0;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
  mounted() {
    this.get();
  },
  watch: {
    $route: 'get',
  },
};
</script>
