<template>
  <div class="transition-opacity" :class="style">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    duration: { type: String, default: '1500' },
  },
  data() {
    return {
      style: 'opacity-0',
    };
  },
  mounted() {
    setTimeout(() => {
      this.style = ' opacity-100 duration-' + this.duration;
    }, 150);
  },
};
</script>
